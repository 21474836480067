.podGridColumn {
  flex-grow: 1;
  width: 100%;
  padding: 0px 48px 0px 0px;
  min-width: 680px;
}

.podGridColumn > * {
  margin-bottom: 32px; /* vertical spacing between pods. 32px here + 16 margin bottom from podcolumn = 48px */
}

.podGridColumnGwt {
  flex-grow: 1;
  width: 100%;
  padding: 0px 0px 0px 0px;
}

.podGridColumnGwt > * {
  margin-bottom: 0px;
  overflow: hidden;
}