@import '../../styles/constants';

.hidden { opacity: 0; pointer-events: none; }

.loaderBg {
  background-color: $background-1;
  padding: 26px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

%shimmerBg {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  background: $shimmer-start linear-gradient(to right, $shimmer-start 0%, $shimmer-end 20%, $shimmer-start 40%, $shimmer-start 100%) no-repeat;
  background-size: 800px 100%;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.titleBox {
  @extend %shimmerBg;
  width: 100%;
  height: 18px;
  margin-bottom: 12px;
}

.contentBox {
  @extend %shimmerBg;
  width: 100%;
  flex-grow: 1;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
