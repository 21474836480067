@import '../../styles/constants';

.cwMultiSelectStyle {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  box-shadow: 0 1px 0px 0px $font-color-2;
  border: none;
}

.cwMultiSelectStyleFocused {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  box-shadow: 0 2px 0px 0px $primary;
}

.cwMultiSelectStyleInvalid {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  box-shadow: 0 2px 0px 0px $color-invalid;
}

.cwMultiSelectStyleDisabled {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  box-shadow: none;
}
