@import '../../styles/constants';

.wrap {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 200px;
  padding: 1px;
}

.list {
  overflow-y: auto;
  border: solid 1px #2a2a2a;
  height: 100%;
  width: 100%;
}

.list:active,
.list:focus {
  box-shadow: 0px 0px 0px 2px $primary;
}

.header {
  font-size: 14px;
  font-weight: $font-bold;
  line-height: 1;
  margin-bottom: 8px;
}

.option {
  padding: 1%;
  cursor: default;
}

.selected {
  background-color: $primary;
  color: white;
}

.dropPreview {
  background-color: transparent;
  border: 1px dashed #026CCF;
}