@import '../../styles/constants';

$error: #E50000;
$informational: #4BAEFA;
$warning: #ED9324;
$successful: #60B006;

.wrap {
  display: block;
  padding: 8px;
  border-radius: 2px;
  background-color: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  flex-shrink: 0;
}
.error {
  border-color: $error;
  fill: $error;
}
.informational {
  border-color: $informational;
  fill: $informational;
}
.warning {
  border-color: $warning;
  fill: $warning;
}
.successful {
  border-color: $successful;
  fill: $successful;
}

.text {
  margin: 0px 8px;
  flex-grow: 1;
}

.icon :global .isvg {
  height: 18px;
  width: 18px;
}

.children {
  margin: 0px 26px; /* 18px icon width + 8px margin */
}