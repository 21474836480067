@import '../../../styles/constants';
@import '../constants';

.cwShellLeftNavButtonIcon {
  fill: $shell-left-nav-button-font-color;
}

.cwShellLeftNavButtonIcon.skinny {
  fill: $shell-left-nav-button-font-color-skinny;
}

.cwShellLeftNavButton {
  display: flex;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  color: $shell-left-nav-button-font-color;
}

.cwShellLeftNavButton.skinny {
  display: flex;
  border: none;
}

.cwShellLeftNavButton:not(.fat):hover,
.cwShellLeftNavButton:not(.fat).active {
  background-color: $shell-left-nav-background-color-hover;
}

.cwShellLeftNavButton:hover,
.cwShellLeftNavButton.active {
  cursor: pointer;
  border-left: 4px solid $primary;
  color: $shell-left-nav-font-color-hover;
  .cwShellLeftNavButtonIcon {
    fill: $shell-left-nav-font-color-hover;
  }
}

.cwShellLeftNavButton.skinny:hover,
.cwShellLeftNavButton.skinny.active {
  cursor: pointer;
  border: none;
  background-color: $shell-left-nav-background-color;
  .cwShellLeftNavButtonIcon {
    fill: $shell-left-nav-button-font-color;
  }
}

.cwShellLeftNavButton label:hover {
  cursor: pointer;
}

.cwShellLeftNavButton.default {
  flex-direction: column;
  justify-content: center;
  min-height: 58px;
  .cwShellLeftNavButtonIcon {
    margin: 4px auto 0 auto;
  }
  .cwShellLeftNavButtonLabel {
    text-align: center;
    margin: 4px 0;
    font-size: 12px;
  }
}

.cwShellLeftNavButton.fat {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  .cwShellLeftNavButtonIcon {
    margin-left: 8px;
    div:first-child {
      width: 18px;
      height: 18px;
    }
  }
  .cwShellLeftNavButtonLabel {
    text-align: left;
    margin-left: 8px;
  }
}

.cwShellLeftNavButton.skinny {
  flex-direction: column;
  justify-content: center;
  min-height: 48px;
  .cwShellLeftNavButtonIcon {
    margin: 0 auto;
  }
}

.cwShellLeftNavLinkContainer {
  background-color: $shell-left-nav-background-color-hover
}

.cwShellLeftNavLinkContainer.default {
  width: 192px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 88px;
  overflow-y: auto;
}

.cwShellLeftNavLinkContainer.skinny {
  padding-top: 8px;
  width: 192px;
  height: 100%;
  position: absolute;
  top: 0;
  left: $shell-left-nav-width-skinny;
  overflow-y: auto;
}

.buttonPopper {
  position: relative;
  left: -4px;
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 12px;
  color: $shell-left-nav-font-color-hover;
  background-color: $shell-left-nav-background-color-popper;
}

.arrow {
  position: absolute;
  left: -12px;
  top: calc(50% - 5px);
  border-width: 5px 8px 5px 0;
  border-style: solid;
  border-color: transparent $shell-left-nav-background-color-popper transparent transparent;
}

.activeRoute {
  .isvg, svg {
    fill: $shell-left-nav-font-color-hover;
  }
}