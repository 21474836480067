@import '../../styles/constants';


.table{
  display: flex;
  justify-content: space-around;
}


.calendarTd{
  color: $font-color;
  padding-left: 8px;
  padding-right: 4px;
  padding-left: 8px;
  padding-right: 4px;
}


.prevNextDays{
  color: $neutral-light;
  padding-left: 8px;
  padding-right: 4px;
  padding-left: 8px;
  padding-right: 4px;
}


.weekDays{
  color: $primary;
  padding: 12px;
}

.calendarTd,.prevNextDays,.weekDays{
  font-family: $font-family;
  font-size: 12px;
  font-weight: $font-bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}









