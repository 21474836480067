@import '../../styles/constants';

.calendar{
  position: fixed;
  background-color: $background-2;
  box-shadow: $shadow-1;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 8px;
  padding-left: 8px;
  z-index: 1;
  margin-top: 2px;
}
