@import '../../styles/constants';

.dropDown {
  background: $background-2;
  box-shadow: $shadow-2;
  border-radius: 0px;
  position: fixed;
  overflow: auto;
  z-index: 1;
  margin-top: 2px;
}
.dropDownItem {
  padding: 8px;
  cursor: default;
  display: flex;
  align-items: center;
  min-height: 1em;
}

.highlightedItem {
  color: white;
  background: $primary;
}
.dropDownPicker {
  padding: 8px;
  cursor: default;
  font-style: italic;
  box-shadow: 0 1px 0px 0px $neutral-light;
}

.dropDownItemWithSeparator {
  @extend .dropDownItem;
  box-shadow: 0 1px 0px 0px $neutral-light;
}