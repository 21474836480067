@import 'theme';

/* gray background colors */
$background-1: #FFFFFF;
$background-2: #F5F5F5;
$background-3: #CCCCCC;
$background-4: #999999;
$background-5: #E6E6E6;

/* font defaults */
$font-family: 'Roboto', sans-serif;
$font-color: #212121;
$font-color-2: #7D7D7D;
$font-bold: 500;

/* font sizes */
$font-size-nav-header: 24px;
$font-size-header: 18px;
$font-size-section-header: 16px;
$font-size-label: 14px;
$font-size-body: 14px;

$font-line-height: 16px;

/* colors */
$color-invalid: #E50000;
$color-today: rgba(1, 108, 207, 0.2);
$color-disabled: #b0b0b0;
$color-primary: #026CCF;
$color-failed: #CA001A;
$color-success: #549C05;

/* links */
$color-link: #0A92FA;
$color-link-hover: #026CCF;

/* shadows */
$shadow-1: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
$shadow-2: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
$shadow-3: 0 2px 8px 0 rgba(0, 0, 0, 0.3);

$standard-button-fontsize: 14px;
$light-button-fontsize-mousemode: 14px;
$light-button-fontsize-touchmode: 16px;

/* shimmer */
$shimmer-start: #F6F7F9;
$shimmer-end: #E9EBEE;
