.paginationContainer {
  display: flex;
}

.resultsCountContainer {
  margin: 0 8px;
  line-height: 18px;
}

.button div {
  padding: 0;
  width: 18px;
  height: 18px;
  svg {
    width: 18px;
    height: 18px;
  }
}
