@import '../../styles/constants';

.currencyRoot {
  display: flex;
}

.currencyText {
  flex-grow: 1;
}

.currencyCode {
  align-self: flex-end;
  flex-shrink: 0;
  padding-bottom: 8px;
  padding-left: 4px;
  box-shadow: 0 1px 0px 0px $font-color-2;
  color: $font-color-2;
}

.currencyText:focus ~ .currencyCode {
  box-shadow: 0 2px 0px 0px $primary;
}