@import "../../styles/constants";

.contextMenu {
  background-color: $background-2;
  -webkit-box-shadow: $shadow-1;
  -moz-box-shadow: $shadow-1;
  box-shadow: $shadow-1;
  display: inline-block;
  max-width: 254px;
}

.contextMenuAnchor {
  display: inline-block;
  position: fixed;
  z-index: 9100;
  width: 1px;
  height: 1px;
}

.contextMenuItem {
  cursor: pointer !important;
  * { cursor: pointer !important; }
  padding: 8px 0 8px 8px;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
}

.contextMenuItem:hover,
.active {
  background-color: $color-primary;
  color: $background-1;
  svg { fill: $background-1; }
}

.contextMenuScrollButton {
  background-color: $background-5;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 18px;
  svg { fill: $font-color-2; }
}

.contextMenuScrollButton:hover,
.contextMenuScrollButton:active {
  background-color: $background-3;
}

.itemArrow {
  display: inline-block;
  width: 18px;
  height: 18px;
  float: right;
  margin-left: 4px;
}

.contextMenuScrollButton,
.itemArrow {
  div {
    padding: 0;
    width: 18px;
    height: 18px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.seperator {
  margin: 0 8px;
  height: 1px;
}

.disabled,
.disabled:hover {
  cursor: default !important;
  * { cursor: default !important; }
  background-color: $background-2;
  color: $color-disabled;
  div svg {
    fill: $color-disabled;
  }
}

.seperator {
  background-color: $background-3;
}