@import '../../styles/constants';

.header{
  padding-top: 16px;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.header svg{
  width: 18px;
  height: 18px;
  font-family: Labtech;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $font-color;
}

.monthYear{
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: $font-bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $font-color;
  padding-left:16px;
  padding-right: 16px;
}