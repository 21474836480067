@import '../../styles/constants';

.headerViewport {
  overflow: hidden;
  z-index:1;
}

.headerCanvas {
  background-color: $background-1;
  position: relative;
  white-space: nowrap;
  display: flex;
}

.textField {
  padding: 0;
  height: 20px;
  margin-top: 0;
}

.columnHeader {
  background-color: $background-1;
  color: $color-primary;
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.columnHeader:hover {
  background-color: $background-2;
  .buttonBG,
  .columnHeaderLabel {
    background-color: $background-2;
  }
}

.columnHeaderLabel {
  background-color: $background-1;
  color: $color-primary;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  border: none;
  outline: none;
  text-align: left;
}

.columnHeader,
.columnHeaderLabel {
  [class*="CwDropdown-dropDownItem"] {
    color: $font-color;
  }
  [class*="CwDropdown-dropDownItem"][class*="CwDropdown-highlightedItem"],
  [class*="CwDropdownMulti-dropDownHighlightedItem"] * {
    color: $background-1;
  }
  [class*="CwPill-pill"] {
    display: none;
  }
}

.clearFilterButton {
  position: absolute;
  top: 4px;
  z-index: 4;
  width: 18px;
  height: 18px;
  div {
    padding: 0;
  }
  [class*="CwButton-innerLightActive"],
  [class*="CwButton-innerLightDisabled"],
  [class*="isvg"] {
    width: 18px;
    height: 18px;
  }
}

.rowsContainer {
  overflow-y: auto;
  overflow-x: auto;
}

.rowsCanvas {
  position: relative;
  white-space: nowrap;
}

.row {
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  text-align: left;
}

.row:hover .cell {
  background-color: $background-2;
  cursor: pointer;
}

.row.active .cell {
  background-color: $background-5;
}

.cell {
  color: $font-color;
  display: inline-block;
  padding: 8px 16px;
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: $background-5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  vertical-align: bottom;
}

.cell.link { color: $color-link; }
.cell.link:active { color: $color-link-hover; }

.cellImage {
  display: inline-block;
  margin-right: 4px;
  float: left;
}

.cellImage.disabled {
  opacity: 0.2;
}

.noResultsContainer {
  position: relative;
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: $background-5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
}

.boolStatus {
  margin: 0 auto;
  width: 18px !important;
  height: 18px !important;
  div, svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.boolStatus.false { fill: $color-failed; }
.boolStatus.true { fill: $color-success; }

.dndBar {
  position: absolute;
  top: 0;
  right: -4px;
  width: 8px;
  height: 31px;
  z-index: 1;
  cursor: col-resize;
}

.dndBar.hasFilterTypes {
  height: 64px;
}

.resizeGuide {
  position: absolute;
  opacity: 0.5;
  border-left: 1px dotted black;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 2;
}

.columnFilterDropdown {
  background: $background-1;
  -webkit-box-shadow: $shadow-3;
  -moz-box-shadow: $shadow-3;
  box-shadow: $shadow-3;
  padding: 15px 15px 19px 15px;
  .columnFilterDropdownHeader {
    color: $font-color-2;
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .columnFilterComboBoxContainer {
    display: inline-block;
    position: relative;
    [class*="CwDropdown-dropDown"]:not([class*="CwDropdown-dropDownItem"]) {
      position: absolute !important;
      left: 0 !important;
      top: 20px !important;
      z-index: 3;
    }
  }
  [class*="CwTextField-textField"]:focus ~ div {
    svg {
      fill: $color-primary;
    }
  }
}

.columnFilterFieldIcon {
  position: absolute;
  bottom: 4px;
  right: 0;
  width: 18px;
  height: 18px;
  .isvg, svg {
    width: 18px;
    height: 18px;
  }
}

.columnReorderDropTarget {
  display: inline-block;
  position: absolute;
  top: -8;
  left: 0;
  height: 32px;
  z-index: 2;

  .leftBorder {
    background-color: $color-primary;
    display: inline-block;
    position: absolute;
    top: 0;
    left: -1px;
    width: 2px;
    height: 100%;
  }

  .rightBorder {
    background-color: $color-primary;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -1px;
    width: 2px;
    height: 100%;
  }
}

.columnReorderDropTarget.rightSide {
  left: auto;
  right: 0;
}

.columnReorderDropTarget.hasFilterTypes {
  height: 64px;
}

.sortArrow {
  fill: $color-primary;
  position: absolute;
  right: 16px;
  top: 8px;
  width: 18px !important;
  height: 18px !important;
  div {
    width: 18px !important;
    height: 18px !important;
  }
}

.multiSelectFilter {
  position: relative;
  .multiSelectFilterValue {
    color: $font-color;
    position: absolute;
    top: 0;
    left: 4px;
    font-size: 12px;
  }
  [class*="CwTiggerField-trigger"] {
    top: 0;
    z-index: 2;
  }
  [class*="CwTextField-textField"] {
    padding-left: 0;
  }
}

.filterContainer {
  position: relative;
  padding: 4px 16px;
  height: 32px;
  box-sizing: border-box;
  [class*="CwTextField-textField"] {
    height: 20px;
    padding: 0;
  }
  [class*="CwImage-cursor"] {
    width: 18px;
    height: 18px;
  }
  [class*="CwTriggerField-trigger"] {
    top: 0;
    z-index: 2;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  [class*="CwButton-innerLightActive"],
  [class*="CwButton-innerLightDisabled"] {
    color: $font-color;
    div {
      color: $font-color;
      fill: $font-color;
    }
    .isvg, svg {
      width: 18px;
      height: 18px;
    }
  }
}

.blankFilter {
  line-height: 20px;
}

.buttonBG {
  background-color: $background-1;
  position: absolute;
  top: 4px;
  right: 0;
  height: 19px;
}

.timeFilterValue {
  color: $font-color;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 4px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeFilterValue:hover {
  color: $color-primary;
}

.dateFilterButton {
  z-index: 2;
}

.dateFilterButton,
.fileSizeFilterButton,
.numberFilterButton,
.timeFilterButton {
  position: relative;
  width: 100%;
  height: 20px;
  border-bottom: thin solid $font-color-2;
  box-sizing: border-box;
  [class*="CwButton-innerLightActive"],
  [class*="CwButton-innerLightDisabled"] {
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-weight: normal;
    justify-content: left;
    line-height: 20px;
    align-items: flex-end;
    padding: 0 4px;
    box-sizing: border-box;
    [class*="isvg"] {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.hoverCursor:hover { cursor: pointer; }

.cell.link:hover,
.cell.link:active {
  text-decoration: underline;
}

.cellCustomFlags {
  display: inline-flex;
  justify-content: center;
  padding: 4px 0px,
}

.focused { color: $primary !important; }

.success { svg { fill: $color-success; }}
.failed { svg { fill: $color-failed; }}

.inMaintenance { svg { fill: #EB7B18; } }
