@import '../../styles/constants';

.dropDown {
  background: $background-2;
  box-shadow: $shadow-2;
  border-radius: 0px;
  position: fixed;
  overflow: auto;
  z-index: 1;
  margin-top: 2px;
}
.dropDownItem {
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 4px;
  cursor: default;
}
.dropDownHighlightedItem {
  @extend .dropDownItem;
  color: white;
  background: $primary;
}
.dropDownPicker {
  padding: 8px;
  cursor: default;
  font-style: italic;
  border-bottom: 1px solid $neutral-light;
}
.dropDownHighlightedPicker {
  @extend .dropDownPicker;
  color: white;
  background: $primary;
}