@import '../../styles/constants';

.hidden { opacity: 0; pointer-events: none; }

.loaderBg {
  background-color: $background-1;
  z-index: 1;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loaderBox {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.loaderText {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 8px;
}

.loading {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  padding: 12px;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
  border: 6px solid transparent;
  border-top-color: $color-primary;
  border-bottom-color: $color-primary;
  border-radius: 50%;
}

.outer {
  animation: spinOuter 1s infinite;
}

.inner {
  animation: spinInner 1s infinite;
}

@keyframes spinInner {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes spinOuter{
  0% {
    transform: rotateZ(0deg) scale(.5);

  }
  50% {
    transform: rotateZ(360deg) scale(1);
  }
  100% {
    transform: rotateZ(360deg) scale(.5);
  }
}
