@import '../../styles/constants';

.wrap {
  user-select: none;
}

.item:hover {
  color: $primary;
  fill: $primary;
}

.itemSelected {
  color: $primary;
  font-weight: $font-bold;
}

.icon {
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  svg {
    height: 18px;
    width: 18px;
  }
}

.label {
  font-size: $font-size-section-header;
  margin-left: 8px;
  height: 26px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subTree {
  padding-left: 16px;
}