@import '../../styles/constants';

.days {
  display:flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin: 2px;
}

.selected{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: $primary;
  border: solid 2px $primary;
  border-radius: 50%;
  margin: 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: $font-bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $background-1;
}

.today{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 2px;
  background-color: $color-today;
  border-radius: 50%;
}
.today:hover,
.days:hover{
  margin: 0;
  border: solid 2px $primary;
  border-radius: 50%;
  cursor:pointer;
  label {
    cursor:pointer;
  }
}