@import '../../styles/constants';

.colorPalette {
  position: fixed;
  background-color: $background-2;
  box-shadow: $shadow-1;
  
  display: flex;
  flex-direction: column;
  padding: 8px;
  z-index: 1;
  margin-top: 2px;
}

.swatch {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor:pointer;
}