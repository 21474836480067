@import '../../styles/constants';
.cwPillTriggerFieldTriggerField {
  flex-wrap: wrap;
  display: flex;
}

.cwPillTriggerFieldTriggerField input,
.cwPillTriggerFieldTriggerField input:focus,
.cwPillTriggerFieldTriggerField input:active,
 {
  flex-basis: 20%;
  flex-grow: 1;
  box-shadow: none;
  height: 20px;
}