@import '../../../styles/constants';
@import '../constants';

.cwShellLeftNavLink {
  color: $shell-left-nav-link-font-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  text-decoration: none;
}

.cwShellLeftNavLink.skinny {
  border: none;
}

.cwShellLeftNavLink:hover {
  cursor: pointer;
  color: $shell-left-nav-font-color-hover;
  border-left: 4px solid $primary;
  text-decoration: none;
}

.cwShellLeftNavLink.skinny:hover {
  border: none;
}

.cwShellLeftNavLink label:hover {
  cursor: pointer;
}

.cwShellLeftNavButtonLabel {
  margin-left: 12px;
}

.cwShellLeftNavLinkIcon {
  fill: $shell-left-nav-button-font-color;
  margin-right: 4px;
  svg {
    width: 18px;
  }
}

.cwShellLeftNavLink.active {
  background-color: $shell-left-nav-background-color-sub-link;
}

.cwShellLeftNavSubLinkContainer {
  background-color: $shell-left-nav-background-color-sub-link;
  .cwShellLeftNavButtonLabel {
    margin-left: 20px;
  }
}