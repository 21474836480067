@import '../../styles/constants';

.toolbar {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-bottom: 2px solid $neutral-dark;
  padding-top: 8px;
  padding-bottom: 6px;
  flex-shrink: 0;
}

.overflowButtons {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.rightButtons {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
