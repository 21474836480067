@import '../../styles/constants';

.modal {
  background-color: $background-1;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  border: solid 1px #cccccc;
  padding: 24px;
  position: fixed;
}

.closeIcon {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 16px;
  right: 16px;
}

.wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  font-size: $font-size-header;
  font-weight: $font-bold;
  flex-shrink: 0;
}

.subHeader {
  font-size: $font-size-section-header;
  flex-shrink: 0;
  margin-top: 8px;
}

.content {
  margin-top: 16px;
}

.buttons {
  margin-top: auto;
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
}

/* Add spacing between all button */
.buttons > * {
  margin-right: 8px;
}
/* Remove unecessary spacing from last button */
.buttons > *:last-child {
  margin-right: 0;
}

.after {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: transparent $background-1 $background-1 transparent;
}

.flyoutTopLeft {
  @extend .modal;
  position: absolute;
}

.flyoutTopLeft:after {
  @extend .after;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  top: -10px;
  left: 16px;
}

.flyoutTopRight {
  @extend .modal;
  position: absolute;
}

.flyoutTopRight:after {
  @extend .after;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
  -moz-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: -10px;
  right: 16px;
}

.flyoutBottomLeft {
  @extend .modal;
  position: absolute;
}

.flyoutBottomLeft:after {
  @extend .after;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  bottom: -10px;
  left: 16px;
}

.flyoutBottomRight {
  @extend .modal;
  position: absolute;
}

.flyoutBottomRight:after {
  @extend .after;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  bottom: -10px;
  right: 16px;
}