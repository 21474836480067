@import '../../styles/constants';

.podCol {
  flex-basis: 0; /* this needs to be here for flex-grow to calculate correctly */
  min-width: 0; /* this needs to be here for nested Pod Columns to not enlarge their parent pod columns and to scale the children */
}

.podColWithoutSectionHeader > * {
  margin-bottom: 16px; /* vertical spacing between podelements */
}

.podColWithSectionHeader > *:nth-child(n+2) {
  margin-bottom: 16px; /* vertical spacing between podelements */
}

.sectionHeader {
  font-size: $font-size-section-header;
  font-weight: $font-bold;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.ghostSectionHeader {
  visibility: hidden;
}