.enlargedBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1025;
  background:rgba(0, 0, 0, 0.8);
  animation: fadein 0.5s;
}

.fill {
  text-align: center;
  display: flex;
  height: 100%;
}

@keyframes fadein {
  from {opacity: 0;}
  to { opacity: 1;}
}

.btns > div{ 
  color: white;
  fill: white;
}

.nav, .zoom, .navLeft, .navRight, .imgCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 3vw;
  height: 5vh;
  font-size: 32px;
  z-index: 1025;
  background:rgba(0, 0, 0, 0.5);
  animation: fadein 0.5s;
  color: white;
}

.navLeft {
  top: 50%;
  left: 0;
}

.navRight {
  top: 50%;
  right: 0;
}

.nav {
  top: 0;
  right: 0;
  width: 5vw;
}

.zoom {
  z-index: 1025;
  bottom: 1%;
  width: 15vw;
  left: 42%;
  justify-content: space-around;
}

.zoomWrap {
  z-index: 1025;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
}

.imgEnlarge {
  align-self: center;
}

.imgCounter {
  top: 0;
  left: 0;
  width: 5vw;
}