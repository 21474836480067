@import '../../styles/constants';

/* vertically display items */
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 48px;
}

/* label */
.label > *:nth-child(1) {
  user-select: none;
  color: $font-color-2;
  font-weight: $font-bold;
  margin-right: auto; /* avoids having label span full width */
  margin-bottom: 8px; 
}

/* On label, add required asterisk */
.required > *:nth-child(1):after {
  content: '*';
}

/* label */
.labelinvalid > *:nth-child(1) {
  color: $color-invalid !important;
}

.labelblue > *:nth-child(1) {
  color: $primary;
}