@import '../../styles/constants';

.wrap {
  width:100%;
  display: inline-block;
  margin-right: auto;
}

.wrap:focus {
  outline: none;
}


.wrap:focus .control input {
  ~ .controlIndicator {
    border: solid 1px $primary;
  }
  ~ .controlIndicatorInvalid {
      border: solid 1px $color-invalid;
  }
  ~ .controlLabelInvalid {
    color: $color-invalid
  }
}

.wrap:focus {
  .control input:disabled,
  .control input:checked:disabled {
    ~ .controlLabel,
    ~ .controlIndicator {
      color: $font-color-2;
      border-color: $font-color-2;
    }

  }
};

.control {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.controlDisabled {
  @extend .control;
  cursor:default;
}

.controlLabel {
  margin-left: 8px;
}

.controlLabelInvalid {
  @extend .controlLabel;
  color: $color-invalid;
}

.control input {
  display: none;
  z-index: -1;
  opacity: 0;
}

.control {
  input:enabled {
    ~ .controlLabel,
    ~ .controlIndicator {
      cursor:pointer;
    }
  }

  input:disabled,
  input:checked:disabled {
    ~ .controlLabel {
      pointer-events: none;
      cursor: default;
      color: $font-color-2;
    }

    ~ .controlIndicator {
      pointer-events: none;
      cursor:default;
      background: $font-color-2;
      border: solid $font-color-2;
      border-width: 1px;
    }
  }

  input:disabled ~ .controlIndicator {
    pointer-events: none;
    opacity: .6;
    background: $background-1;
    border: solid $font-color-2;
    border-width: 1px;
  }

  input:checked {

    ~.controlIndicator {
      background: $primary;
      border: solid $primary;
      border-width: 1px;
    }

    ~ .controlLabelInvalid {
      color: $color-invalid;
    }

    ~ .controlIndicatorInvalid {
      background: $color-invalid;
      border: solid $color-invalid;
      border-width: 1px;
    }
  }

  input:checked ~.controlIndicator:after, .controlIndicatorInvalid:after {
    display: block;
    position: relative;
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border-width: 2px;
    background: $background-1;
  } 

  input:disabled ~ .controlIndicator:after {
    background: $background-1;
    border: $font-color-2;
    border-width: 2px;
  }
}

.controlIndicator {
  width: 16px;
  height: 16px;
  border: solid black;
  border-width: 1px;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
}
.controlIndicator:after {
  display: none;
  content: '';
}

.control .controlIndicator {
  border-radius: 60%;
}

.controlIndicatorInvalid {
  @extend .controlIndicator;
  border: solid $color-invalid;
  border-width: 1px;
}