@import '../../styles/constants';

.colorField {
  width: 100%;
}

.colorFieldTriggerField input {
  padding-left: 30px;
}

.swatchSelected {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px #dddddd;
}