@import '../../styles/constants';

.textField {
  border: none;
  border-bottom: 1px;
  border-color: transparent;
  box-shadow: 0 1px 0px 0px $font-color-2;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 1px 0px 0px $font-color-2;
  padding: 0px 4px 8px;
  background: transparent;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
}

.textField:active,
.textField:focus {
  box-shadow: 0 2px 0px 0px $primary;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 2px 0px 0px $primary;
}

.textField:disabled {
  color: $color-disabled;
  border-bottom: dotted 1px $color-disabled;
  box-shadow: none;
  -webkit-appearance: none;
  margin-bottom: -1px;
}

.textFieldInvalid {
  border: none;
  padding: 0px 4px 8px;
  border-bottom: 2px;
  border-color: transparent;
  box-shadow: 0 2px 0px 0px $color-invalid;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 2px 0px 0px $color-invalid;
  background: transparent;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}