@import '../../styles/constants';

.wrap {
  display: flex;
  align-items: flex-end;
  outline: none;
}

.wrapWithTextField {
  @extend .wrap;
  box-shadow: 0 1px 0px 0px $font-color-2;
}

.wrapWithTextFieldInvalid {
  @extend .wrap;
  box-shadow: 0 2px 0px 0px $color-invalid;
}

.wrapDisabled {
  @extend .wrap;
  color: $color-disabled;
  border-bottom: dotted 1px $color-disabled;
  box-shadow: none;
}

.inputFileText {
  flex-grow: 1; /* Here so the file name field will fill the empty space on the control */
}

.wrap > *:focus {
  box-shadow: none;
}

.wrap > * {
  box-shadow: none;
}

.wrapWithTextField:focus,
.wrapWithTextField:active {
  box-shadow: 0 2px 0px 0px $primary;
}

.wrapWithTextField:focus,
.wrapWithTextField:active {
  .browseButton > * {
    color: $primary;
  }
}

.wrapWithTextField:hover {
  box-shadow: 0 2px 0px 0px $secondary;
}

.wrapWithTextField:hover > .browseButton > * {
  color: $secondary;
}

.file {
  display: none;
  width: 0;
}
