@import '../../styles/constants';

.baseHeaderDyn {
  display: flex;
  align-items: center;
  user-select: none;
}
.headerDynCollapse {
  padding-bottom: 0;
}

.headerDynExpand {
  padding-bottom: 2.2vh;
}

.headerDynAnimateCollapse {
  animation: collapsePodHeader 0.75s forwards;
}

.headerDynAnimateExpand {
  animation: expandPodHeader 0.75s forwards;
}

@keyframes collapsePodHeader {
  from {padding-bottom: 2.2vh}
  to {padding-bottom: 0}
}

@keyframes expandPodHeader {
  from {padding-bottom: 0vh}
  to {padding-bottom: 2.2vh}
}

.expandCollapseButton {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row-reverse;
}

.headerTextDyn {
  font-size: $font-size-header;
  line-height: 24px;
  color: $primary;
  font-weight: $font-bold;
}

.ghostTitleDyn {
  visibility: hidden;
}

.nestedDyn {
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.animateCollapse {
  overflow: hidden;
  animation: collapsePod 0.75s forwards;
  }

.collapsed {
  overflow: hidden;
  height: 0;
}

.animateExpand {
  animation: expandPod 0.75s forwards;
}

.expand {
  height: 100%;
}

@keyframes collapsePod {
  from {max-height: 500px}
  to {max-height: 0}
}
@keyframes expandPod {
  from {max-height: 0}
  to {max-height: 500px}
}
