.wrap {
  overflow: auto;
}

.podLayoutGrid {
  height: 100%;
  padding: 0px 0px 0px 0px;
}

.defaultDynLayout {
  display: flex;
}
