@import '../../styles/constants';

.wrap {
  display: inline-block;
  margin-right: auto;
}

.wrap:focus {
  outline: none;
}

.wrap:focus .control {
  .checkbox,
  .checkboxChecked {
    border: solid 1px $primary;
  }
  label {
    color: $primary;
  }
}

.wrap:focus .controlDisabled {
  .checkboxCheckedDisabled,
  .checkboxDisabled {
    border: solid 1px $color-disabled;
  }
  label {
    color: $color-disabled;
  }
}

.wrap:focus .controlInvalid {
  .checkboxInvalid,
  .checkboxCheckedInvalid{
    border: solid 1px $color-invalid;
  }
  label {
    color: $color-invalid;
  }
}

.control {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
}

.control label {
  cursor: pointer;
  margin-left: 8px;
  user-select: none;
}

.controlDisabled {
  @extend .control;
  cursor: default;
}

.controlDisabled label {
  cursor: default;
}

.checkbox {
  width: 16px;
  height: 16px;
  border: solid $font-color;
  border-width: 1px;
  border-radius: 3px;
  cursor: pointer;
  flex-shrink: 0;
}

.checkboxChecked {
  width: 16px;
  height: 16px;
  background: $primary;
  border: solid #1f65d1;
  border-width: 1px;
  border-radius: 3px;
  cursor: pointer;
  flex-shrink: 0;
}

.checkboxInvalid {
  width: 16px;
  height: 16px;
  border: solid $color-invalid;
  border-width: 1px;
  border-radius: 3px;
  cursor: pointer;
  flex-shrink: 0;
  + label {
    color: $color-invalid
  }
}

.checkboxCheckedInvalid {
  width: 16px;
  height: 16px;
  background: $color-invalid;
  border: solid $color-invalid;
  border-width: 1px;
  border-radius: 3px;
  cursor: pointer;
  flex-shrink: 0;
  + label {
    color: $color-invalid
  }
}

/* Check mark */
.checkboxChecked:after {
  position: relative;
  display: block;
  content: '';
  top: 3px;
  left: 6px;
  width: 3px;
  height: 6px;
  transform: rotate(45deg);
  border: solid $background-1;
  border-width: 0 2px 2px 0;
}

.checkboxDisabled {
  border: solid $color-disabled;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-radius: 3px;
  cursor: default;
  flex-shrink: 0;
}

.checkboxDisabled ~ label {
  cursor: default;
  color: $color-disabled;
}

.checkboxCheckedDisabled {
  width: 16px;
  height: 16px;
  background: $color-disabled;
  border: solid $color-disabled;
  border-width: 1px;
  border-radius: 3px;
  cursor: default;
  flex-shrink: 0;
}

.checkboxCheckedDisabled ~ label {
  cursor: default;
  color: $color-disabled;
}

.checkboxCheckedDisabled:after {
  position: relative;
  display: block;
  content: '';
  top: 3px;
  left: 6px;
  width: 3px;
  height: 6px;
  transform: rotate(45deg);
  border: solid $background-1;
  border-width: 0 2px 2px 0;
  background: $color-disabled;
}

.checkbox:after {
  display: none;
  content: '';
}

