@import '../../../styles/constants';

.cwShellTopNavLogo {
  width: 88px;
  height: 48px;
  float: left;
}

.cwShellTopNavLogoImage {
  width: 36px;
  margin: 6px auto;
  fill: $background-1;
  svg {
    width: 36px;
  }
}