@import 'constants';

body {
  background-color: $background-1;
}

body, ul {
  margin: 0;
  padding: 0;
}

body, table, td, select, textarea, input {
  font-family: $font-family;
  font-size: $font-size-label;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  line-height: $font-line-height;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

:global .isvg {
  height: 24px;
  width: 24px;
}