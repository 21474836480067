@import '../../styles/constants';

// Default
.wrap {
  display: flex;
  flex-direction: column;
}

// List Stylings
.list {
  width: 292px;
  height: 144px;
  border: solid 1px #2a2a2a;
  margin-bottom: 8px;
  background: transparent;
}

.list:active,
.list:focus {
  box-shadow: 0px 0px 0px 2px $primary;
}

.list:disabled {
  color: $color-disabled;
  border: dotted 1px $color-disabled;
  background-color: $background-1;
  box-shadow: none;
  margin-left: 0px;
  margin-bottom: 8px;
}

.listInvalid {
  padding: 8px 8px;
  border-radius: 2px;
  border: solid 2px $color-invalid;
  background-color: $background-1;
  resize: none;
}

// Header Stylings
.header {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #212121;
  margin-bottom: 8px;
}

// Filter Stylings
.filter {
  border: none;
  border-bottom: 1px;
  border-color: transparent;
  box-shadow: 0 1px 0px 0px $font-color-2;
  padding: 0px 4px 8px;
  margin-bottom: 8px;
}
.filter:active,
.filter:focus {
  box-shadow: 0 2px 0px 0px $primary;
}

.filter:disabled {
  color: $color-disabled;
  border-bottom: dotted 1px $color-disabled;
  background-color: $background-1;
  box-shadow: none;
}

.filterInvalid {
  border: none;
  padding: 0px 4px 8px;
  border-bottom: 2px;
  border-color:transparent;
  box-shadow: 0 2px 0px 0px $color-invalid;
  margin-top: 8px;
}

.alignCenter{
  text-align: center;
}

.alignRight{
  text-align: right;
}