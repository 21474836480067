@import '../../styles/constants';
@import './constants';

.cwShellLeftNav {
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.cwShellLeftNav.collapsed {
  background-color: 'transparent';
  width: 32px;
}

.cwShellLeftNav.default {
  background-color: $shell-left-nav-background-color;
  width: 88px;
}

.cwShellLeftNav.fat {
  background-color: $shell-left-nav-background-color;
  width: 192px;
  .cwShellLeftNavContainer {
    overflow-y: auto;
    height: 100%;
  }
}

.cwShellLeftNav.skinny {
  background-color: $shell-left-nav-background-color;
  width: $shell-left-nav-width-skinny;
  .cwShellLeftNavContainer {
    padding-top: 8px;
  }
}

.expansionIconContainer {
  margin: 4px;
  min-height: 18px;
}

.expansionIcon {
  fill: $shell-left-nav-button-font-color;
  div:first-child {
    width: 18px;
    height: 18px;
  }
}

.expansionIcon:hover {
  fill: $shell-left-nav-font-color-hover;
  cursor: pointer;
}

.collapseIcon:not(.fat) {
  float: left;
}

.collapseIcon.fat {
  float: right;
}

.expandIcon {
  float: right;
}

.expandIcon.collapsed {
  fill: $font-color;
}
