@import '../../styles/constants';

// Default
.wrap {
  display: flex;
  
  div {
    display: flex;
    flex-direction: column;
  }
}

.buttons {
  padding: 14px 38px;
  align-self: center;
}