@import '../../styles/constants';

// Default

.triggerField {
  position: relative;
  display:flex;
  align-items: center;
  width:100%;
}

.triggerField input {
  padding-right: 24px;
  flex-grow: 1;
  width: 100%;
}

.triggerField input:focus ~ div {
  fill: $primary;
}

.trigger {
  position: absolute;
  top: -4px;
  right: 0px;
}

// Invalid

.triggerInvalid {
  position: absolute;
  top: -4px;
  right: 0px;
  fill: $color-invalid
}

// Disabled

.triggerDisabled {
  position: absolute;
  top: -4px;
  right: 0px;
  fill: $color-disabled;
}

.customElement {
  position: absolute;
  left: 2px;
  bottom: 9px;
  width: 16px;
  height: 16px;
  padding-bottom: 4px;
}

.triggerFieldSpace input {
  padding-left: 30px;
}