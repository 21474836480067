@import '../../styles/constants';

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.bold {
  font-weight: $font-bold;
}

.italic {
  font-style: italic;
}

.invalid {
  color: $color-invalid !important;
}

.disabled {
  color: $color-disabled;
}

.podLabel {
  user-select: none;
  color: $font-color-2;
  font-weight: $font-bold;
  margin-right: auto; /* avoids having label span full width */
  margin-bottom: 8px;
}

.link {
  color: $primary;
  cursor: pointer;
  outline: none;
}
.link:hover {
  text-decoration: underline;
}
.link:active,
.link:focus {
  color: #0A92FA;
  text-decoration: underline;
}