@import '../../styles/constants';

.header {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  user-select: none;
}

.headerText {
  font-size: $font-size-header;
  line-height: 24px;
  color: $primary;
  font-weight: $font-bold;
  width: 100%;
}

.ghostTitle {
  visibility: hidden;
}

.nested {
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}