@import '../../styles/constants';

.textArea {
  border: none;
  box-shadow: 0px 0px 0px 1px $font-color-2;
  padding: 8px 4px;
  resize: none;
  margin-left: 2px;
  margin-right: 2px;
  background: transparent;
}

.textArea:active,
.textArea:focus {
  box-shadow: 0px 0px 0px 2px $primary;
}

.textArea:disabled {
  color: $color-disabled;
  border: dotted 1px $color-disabled;
  box-shadow: none;
  margin-left: 0px;
  margin-right: 0px;
}

.textAreaInvalid {
  padding: 8px 8px;
  border-radius: 2px;
  border: solid 2px $color-invalid;
  resize: none;
  background: transparent;
}