@mixin boxShadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

@mixin borderRadius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin transition($duration) {
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -o-transition: all $duration ease;
  transition: all $duration ease;
}
