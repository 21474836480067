@import '../../styles/constants';

.wrap {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  padding: 4% 5% 0% 5%;
}