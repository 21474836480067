@import '../../styles/constants';

.wrap {
  display: inline-flex;
  outline: none;
  user-select: none;
  vertical-align: middle;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  height: 32px;
  line-height: normal;
}
.innerStandard {
  @extend .inner;
  color: $background-1;
  padding: 0px 16px;
  border-radius: 4px;
  border-width: 0px;
  font-size: $standard-button-fontsize;
}
.innerStandardGrid {
  @extend .innerStandard;
}
.innerStandardActive {
  @extend .innerStandard;
  background-color: $primary;
}
.innerStandardActiveGrid {
  @extend .innerStandardActive;
}
.innerLight {
  @extend .inner;
  padding: 0px 8px;
  border: none;
  font-size: $light-button-fontsize-touchmode;
}
.innerLightGrid {
  @extend .innerLight;
}
.innerLightActive {
  @extend.innerLight;
  color: $font-color;
  fill: $font-color;
}
.innerLightActiveGrid {
  @extend .innerLightActive;
}
.wrap:hover .innerStandardActive {
  background-color: $secondary;
}

.wrap:hover .innerLightActive, 
.topIcon:hover {
  color: $secondary;
  fill: $secondary;
}

.wrap:active .innerStandardActive,
.wrap:focus .innerStandardActive {
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: $primary;
}
.wrap:active .innerLightActive,
.wrap:focus .innerLightActive,
.topIcon:focus {
  color: $primary;
  fill: $primary;
}

.innerStandardDisabled {
  @extend .innerStandard;
  background-color: $color-disabled;
  cursor: default;
}

.innerStandardDisabledGrid {
  @extend .innerStandardDisabled;
}

.innerLightDisabled {
  @extend .innerLight;
  color: $color-disabled;
  cursor: default;
  fill: $color-disabled;
}

.innerLightDisabledGrid {
  @extend .innerLightDisabled;
}

.topIconClassDisabled {
  fill: $color-disabled;
}

.topIcon {
  flex-direction: column;
  align-items: center;
}

.rightIcon {
  height:18px;
  width:18px;
  padding-left:5px;
}