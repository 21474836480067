
@import '../../styles/constants';

.pill {
  position: relative;
  left: 2px;
  border-radius: 100px;
  background: $tertiary;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  cursor: default;
  white-space: nowrap;
}

.closeIcon {
  vertical-align: middle;
  height: 12px;
  width: 12px;
  margin-left: 8px;
}