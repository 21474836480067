@import '../../styles/constants';

.gridBodyExpandable > tr {
  background-color: $background-2 !important;
}

.gridBodyExpandable > tr:nth-child(4n),
.gridBodyExpandable > tr:nth-child(4n-1) {
  background-color: unset !important;
}

.wordWrap {
  overflow: auto !important;
  white-space: normal !important;
  text-overflow: initial !important;
}

.cellLeft {
  text-align: left;
}

.cellCenter {
  text-align: center;
}

.cellRight {
  text-align: right;
}

.headerRow > *:hover {
  background-color: $background-2;
}

.contentRow > td,
.headerRowSecond > th {
  padding-top: 0 !important;
  min-height: 32px
}

.gridBody .contentRow:hover,
.gridBodyExpandable .contentRow:hover {
  background-color: $tertiary !important;
}

.sortIcon {
  height: 14px !important;
  width: 14px !important;
}