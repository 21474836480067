@import '../../styles/constants';
@import '../../styles/mixin';

.flyout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  @include transition(0.5s);
}

.flyout.shown {
  opacity: 1;
  visibility: visible;
  @include transition(0.5s);
}

.flyoutBg {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $font-color;
  opacity: 0.8;
  z-index: 1;
}

.closeIcon {
  cursor: pointer;
  * { cursor: pointer; }
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  width: 2rem;
  height: 2rem;
  z-index: 2;
  div, svg {
    width: 2rem;
    height: 2rem;
    fill: $background-1;
  }
}

.flyoutContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: -62.5rem;
  width: calc(100vw - 3.5rem);
  height: 100%;
  background-color: $background-1;
  z-index: 2;
  @include transition(0.5s);
  @include borderRadius(0.25rem)
}

.flyoutHeader {
  width: 100%;
  display: flex;
  padding: 0 38px;
  height: 80px;
  justify-content: space-between;
  align-items: center;
}

.flyoutTitle {
  font-size: 18px;
  font-weight: 500;
}

.iFrame {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
}

.shown {
  .flyoutContent {
    right: 0;
    @include transition(0.5s);
  }
}
