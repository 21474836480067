@import '../../../../styles/constants';

.fieldContainer {
  width: 50%;
  float: left;
  box-sizing: border-box;
}

.fieldLabel {
  font-weight: bold;
  margin-bottom: 8px;
}

.dialogContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9001;
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.error {
  visibility: visible;
  color: $color-failed;
}
