@import '../../styles/constants';

.cwShellTopNav {
  background-color: $background-1;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}