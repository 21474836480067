@import '../../styles/constants';

.table,
.tableAlternateRow {
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.table > thead > tr > th,
.tableAlternateRow > thead > tr > th {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px;
  font-size: $font-size-label;
  font-weight: $font-bold;
  color: $primary;
}

.table > tbody > tr > td,
.tableAlternateRow > tbody > tr > td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 32px;
}

.tableAlternateRow > tbody > tr:nth-child(odd) {
  background-color: $background-2;
}

.nested thead > tr > th {
  color: $font-color-2;
}