@import '../../styles/constants';

.wrap {
  display: inline-flex;
  outline: none;
  user-select: none;
  vertical-align: middle;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  margin: 2px;
  padding: 0px 16px;
  height: 32px
}

.innerEnabled {
  @extend.inner ;
  color: $font-color;
}

.innerActive {
  @extend.inner ;
  color: $primary;
  border: solid 2px $primary;
  margin: 0px;
  border-radius: 4px;
}