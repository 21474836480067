@import '../../styles/constants';

.wrap {
  display: flex;
}

.verticalAlign {
  align-items: center;
}

.horizontalAlign {
  justify-content: center;
}

.column {
  flex-direction: column;
}

.leftAlign {
  justify-content: flex-start;
}
.centerAlign {
  justify-content: center;
}
.rightAlign {
  justify-content: flex-end;
}